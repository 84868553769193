<template>
  <v-app class="card_container">
    <div v-if="loading" class="card">
      <div class="loader_container">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <div v-else-if="invoiceId === ''" class="card">
      <img src="../assets/process_failure.png" alt="" class="logo" />
      <p class="invoice_error">Invoice ID not provided</p>
    </div>
    <div v-else-if="paid" class="card">
      <img src="../assets/process_success.png" alt="" class="logo" />
      <p class="invoice_paid">Invoice already Paid</p>
    </div>
    <div class="card" v-else>
      <form ref="paymentForm" :action="fiservUrl" method="post" @submit.prevent="handleSubmit()">
        <v-container class="container">
          <img src="../assets/logo.png" alt="OnTrac Logo" />
          <p class="Header">Order Form</p>
          <p class="Header2"><b>Customer:</b> {{ customerName }}</p>
          <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
          <input type="hidden" name="language" value="en_US" />
          <input type="hidden" name="hashExtended" value="" ref="hashExtended" />
          <input type="hidden" name="storename" :value="storename" />
          <input type="hidden" name="full_bypass" value="false" />
          <input type="hidden" name="dccSkipOffer" value="false" />
          <input type="hidden" name="paymentMethod" value="M" />
          <input type="hidden" name="timezone" :value="timezone" />
          <input type="hidden" name="txndatetime" :value="txndatetime()" />
          <input type="hidden" name="txntype" value="sale" />
          <v-row>
            <v-col>
              <p><b>Total</b></p>
            </v-col>
            <v-col>
              <p class="total">{{ currencyCode + total.toFixed(2) }}</p>
            </v-col>
          </v-row>
          <input type="hidden" name="chargetotal" :value="total" readonly />
          <input type="hidden" name="currency" :value="currencyISO" />
          <!-- <input type="hidden" name="currency" value="840" /> -->

          <input type="hidden" name="responseFailURL" :value="response_failure_url" />
          <input type="hidden" name="responseSuccessURL" :value="response_success_url" />
          <input type="hidden" name="customParam_invoiceId" :value="invoiceId" />
          <input type="hidden" name="customParam_userId" :value="userId" />
          <input class="button" type="submit" value="PAY" />
        </v-container>
      </form>
    </div>
  </v-app>
</template>

<script>
import CryptoJS from "crypto-js";
import moment from "moment-timezone";
import axios from "axios";

export default {
  name: "App",

  components: {},

  data: () => ({
    terms: true,
    paid: false,
    loading: true,
    timezone: "America/New_York",
    hashExtended: "",
    invoiceId: "",
    userId: "",
    paymentType: "",
    total: 0,
    customerName: "",
    storename: process.env.VUE_APP_STORENAME,
    sharedSecret: process.env.VUE_APP_SHAREDSECRET,
    fiservUrl: process.env.VUE_APP_FISERV_ENVIRONMENT_URL,
    firebaseUrl: process.env.VUE_APP_FIREBASE_URL,
    response_failure_url: process.env.VUE_APP_RESPONSE_FAILURE_URL,
    response_success_url: process.env.VUE_APP_RESPONSE_SUCCESS_URL,
    currencyCode: "",
    currencyISO: "",
  }),
  methods: {
    txndatetime() {
      return moment().tz(this.timezone).format("YYYY:MM:DD-HH:mm:ss");
    },
    handleSubmit() {
      // Payment Form
      const paymentForm = this.$refs.paymentForm;
      // Extract Payment Form Parameters
      const paymentParameters = Array.from(paymentForm.elements)
        .filter((item) => item.name && item.value !== "")
        .reduce((obj, item) => {
          obj[item.name] = item.value;
          return obj;
        }, {});
      // console.log("paymentParameters", paymentParameters);
      // Prepare Message Signature Content
      const ignoreSignatureParameters = ["hashExtended"];
      const messageSignatureContent = Object.keys(paymentParameters)
        .filter((key) => !ignoreSignatureParameters.includes(key))
        .sort()
        .map((key) => paymentParameters[key]);

      // console.log('this.sharedSecret', this.sharedSecret)
      // console.log('messageSignatureContent', messageSignatureContent.join("|"))
      // Calculate Message Signature
      const messageSignature = CryptoJS.HmacSHA256(
        messageSignatureContent.join("|"),
        this.sharedSecret
      );
      // console.log("messageSignature", messageSignature);

      const messageSignatureBase64 =
        CryptoJS.enc.Base64.stringify(messageSignature);
      // console.log("messageSignatureBase64", messageSignatureBase64);

      // Update Form Parameters
      this.$refs.hashExtended.value = messageSignatureBase64;

      // You can also submit the form if needed
      this.$refs.paymentForm.submit();
    },
  },
  async mounted() {
    console.log({ env: process.env.VUE_APP_RESPONSE_ENVIROMENT, url_backend: this.firebaseUrl, failed: this.response_failure_url, succ: this.response_success_url });
    const urlParams = new URLSearchParams(window.location.search);
    this.invoiceId = urlParams.get("invoice_id") || "";
    this.paymentType = urlParams.get("payment_type") || "";
    if (this.invoiceId != "") {
      try {
        const response = await axios(this.firebaseUrl + "/zoho/getData", {
          method: "post",
          data: {
            invoiceId: this.invoiceId,
            userId: this.userId,
          },
        }); 
        this.total = response.data.data.invoice.total;
        this.customerName = response.data.data.invoice.customer_name;
        this.paid = response.data.data.invoice.balance == 0 ? true : false;
        this.userId = response.data.data.invoice.created_by_id;
        this.currencyCode = response.data.data.invoice.currency_code;
         const getCurrency = await axios(
          this.firebaseUrl + "/zoho/currencies/" + this.currencyCode
        );
        this.currencyISO = getCurrency.data.data.ISO;
        console.log(this.currencyISO);
        // this.invoiceType = response.data.data.invoice.custom_fields.find(cf => cf.field_id == "4571681000002036153")?.value
        this.loading = false;
      } catch (err) {
        console.log("err", err);
      }
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.card_container {
  font-family: Arial, Helvetica, sans-serif;
  /* background-image:url(../assets/On-Trac-Website-Background-2.png); */
  background-color: #939597;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Ajusta la altura al 100% de la ventana */
  overflow-y: auto;
}

.Header {
  color: #f58220;
  margin: auto;
  width: 40%;
  padding: 20px;
  font-size: 1.5rem !important;
}

.Header2 {
  color: #111010;
  margin: auto;
  /* padding: 10px; */
  padding-bottom: 20px;
}

.card {
  /* margin: 0 auto !important; */
  /* height: auto; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  font-family: "Raleway", sans-serif;
  font-size: large;
  background-color: white;
  border: 0px solid #bacdd8;
  padding: 18px;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  padding: 20px;
}

img {
  display: block;
  margin: auto;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.button {
  background-color: #f58220;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
}

.button:hover {
  opacity: 0.8;
}

.total_container {
  border: 2px solid #f58220;
  padding: 10px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.invoice_error {
  color: red;
  text-align: center;
  font-size: 2rem;
}

.invoice_paid {
  color: green;
  text-align: center;
  font-size: 2rem;
}

.loader_container {
  /* margin:auto; */
  /* width: fit-content; */
  height: 400px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f58220;
  border-color: #f58220 transparent #f58220 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
